import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { PilotService } from '../services/pilot.service';
import { Location } from '@wingstop/models/location/location.model';

@Injectable()
export class PilotLocationGuardService implements CanActivate {
  private selectedLocation: Location;

  constructor(
    public router: Router,
    private store: State<IAppStore>,
    private pilotService: PilotService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      // Only run this if the pilot program redirects are enabled

      // Need to ensure that the CMS flags are set before we can proceed
      await this.pilotService.featuresSet();
      if (this.pilotService.getPilotRedirectEnabled()) {
        this.selectedLocation = this.store.getValue().appState.selectedLocation;

        const path = route?.routeConfig?.path;
        // If a basket exists with items then we need to allow other logic on product and menu page to continue.
        // No locations means this is a direct route link and we need to get the API data
        // Or if the selected location is not the same as the route path then we need to get the API data
        if (
          !this.pilotService.haveBasketWithItems() &&
          (!this.selectedLocation ||
            (path &&
              this.selectedLocation &&
              this.selectedLocation?.slug !== path))
        ) {
          await this.waitForSelectedLocation(route);
        }
        // This will check for the param and set data.
        // Then the redirect method will use the data to determine how to proceed
        this.pilotService.handleRouteHasNgfeOrder(route);

        // Check if we need to redirect based on the Pilot Program
        if (
          this.pilotService.isPilotLocationAndShouldRedirect(
            this.selectedLocation
          )
        ) {
          await this.pilotService.redirectToNgfeWebsite(
            null,
            this.selectedLocation
          );
          return false;
        }
      }
    } catch (error) {
      // Any errors above allow the customer to visit
      return true;
    }

    return true;
  }

  private async waitForSelectedLocation(
    route: ActivatedRouteSnapshot
  ): Promise<void> {
    try {
      await this.pilotService.getLocation(route).then(async (location) => {
        this.selectedLocation = location;
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  }
}
