import { AccountsModule, WriCommonModule } from '@wingstop-inc/ngfe-web';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/footer/footer.component';
import { IconComponent } from '@wingstop/global/icon/icon.component';
import { MobileAppBannerComponent } from './components/mobile-app-banner/mobile-app-banner.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SizeDetectorComponent } from './components/size-detector/size-detector.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    IconComponent,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    WriCommonModule,
    AccountsModule,
  ],
  declarations: [
    FooterComponent,
    MobileAppBannerComponent,
    NavbarComponent,
    SizeDetectorComponent,
  ],
  exports: [
    FooterComponent,
    MobileAppBannerComponent,
    NavbarComponent,
    SizeDetectorComponent,
  ],
  providers: [],
})
export class BootstrapModule {}
