import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@wingstop/models/location/location.model';

@Pipe({
  name: 'locationComingSoon',
  standalone: true,
})
export class LocationComingSoonPipe implements PipeTransform {
  transform(location: Location, ...args: unknown[]): boolean {
    return location.isComingSoon();
  }
}
