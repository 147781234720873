import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { Location } from '@wingstop/models/location/location.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Basket } from '@wingstop/models/basket.model';
import { ProductRedirect } from '@wingstop/models/menu/product-redirect.model';
import { Injectable } from '@angular/core';
import { IAppStore } from '@wingstop/store/app-store';
import { State } from '@ngrx/store';
import { ProductService } from '@wingstop/services/product.service';
import { environment } from '@wingstop/environments/environment';
import moment from 'moment-mini';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { RedirectTo } from '@wingstop/models/basket/basket-redirect.model';
import { PilotService } from './pilot.service';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private static DEBUG = false;
  private static DEBUG_TIME_TRAVEL_TO = moment()
    .set('hour', 6)
    .set('minute', 0);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: State<IAppStore>
  ) { }

  public externalSite(
    appStateActions: AppStateActions,
    event: MouseEvent,
    url?: string
  ) {
    event.preventDefault();
    const target = <HTMLAnchorElement>event.currentTarget;

    appStateActions.openAlertModalWith(
      'CONFIRM',
      'You are about to be redirected to another site that is not Wingstop.com, and may be subject to separate terms, conditions, and privacy policies. You can press "Ok" to proceed, or "Cancel" to stay here.',
      'Ok',
      null,
      'Cancel',
      true,
      false,
      (result: string) => {
        if (result === 'ok') {
          if (target.href) {
            window.open(target.href, '_blank');
          } else {
            window.open(url, '_blank');
          }
        }
      }
    );
  }

  public static getNow() {
    if (GlobalService.DEBUG) {
      console.log(
        'GlobalService: In debug mode. Now time: ' +
        GlobalService.DEBUG_TIME_TRAVEL_TO.toString()
      );
    }

    return GlobalService.DEBUG ? GlobalService.DEBUG_TIME_TRAVEL_TO : moment();
  }

  public static logGaEvent(event: any) {
    try {
      dataLayer.push(event);
    } catch (e) {
      console.error(e);
    }
  }
  public static getEarliestReadyMoment(
    location: Location,
    basket: Basket | OrderResponse,
    adjustTimezone = true
  ) {
    let hoursDiff = 0;
    if (adjustTimezone) {
      let myOffset = +(GlobalService.getNow().utcOffset() / 60);
      let locationOffset = +location.utcoffset;
      hoursDiff = myOffset - locationOffset;
    }

    let earliestReadyTimeInMyTimeZone: moment.Moment;
    if (basket instanceof Basket) {
      earliestReadyTimeInMyTimeZone = basket.earliestreadytime.clone();
    } else {
      earliestReadyTimeInMyTimeZone = basket.readytime.clone();
    }
    earliestReadyTimeInMyTimeZone.add(hoursDiff, 'hours');
    earliestReadyTimeInMyTimeZone.set('seconds', 59);

    return earliestReadyTimeInMyTimeZone;
  }

  public static getWaitTimeLabel(
    location: Location,
    basket: Basket | OrderResponse,
    twoLines = false
  ) {
    let description = '';
    let time = '';

    if (basket.inASAPMode()) {
      if (basket instanceof Basket) {
        let waitTime = basket.leadtimeestimateminutes;
        let waitTimeLower = waitTime + environment.orderWindow.lower;
        let waitTimeUpper = waitTime + environment.orderWindow.upper;
        time = waitTimeLower + ' – ' + waitTimeUpper + ' minutes';
      } else {
        // Get the diff between our current client time and the store's time
        let waitTime = GlobalService.getEarliestReadyMoment(
          location,
          basket,
          false
        );
        // Diff the two moment objects in minujtes
        let diff = waitTime.diff(basket.timeplaced, 'minutes');
        // Add the upper and lower..
        let waitTimeLower = diff + environment.orderWindow.lower;
        let waitTimeUpper = diff + environment.orderWindow.upper;
        // Update the label
        time = waitTimeLower + ' – ' + waitTimeUpper + ' minutes';
      }

      if (basket.deliverymode === 'pickup') {
        description = 'Ready for carryout in about';
      }
      if (basket.deliverymode === 'curbside') {
        description = 'Ready for curbside pickup in about';
      }
      if (basket.deliverymode === 'dispatch') {
        description = 'Delivery in about';
      }
      if (basket.deliverymode === 'dinein') {
        description = 'Ready for dine-in in about';
      }
    } else if (basket.inLaterMode()) {
      let end: any;
      if (basket instanceof Basket) {
        end = basket.timewanted
          ? basket.timewanted
          : GlobalService.getEarliestReadyMoment(location, basket, false);
      } else {
        end = GlobalService.getEarliestReadyMoment(location, basket, false);
      }

      // earliest ready time doesn't have seconds component but "now" does.
      // this causes us to be off by 1 minute. Ex: 9:30AM - 9:14:47AM = 15 whole minutes, not 16 as it should be
      // here, we set the seconds to 59 to compensate
      end.set('seconds', 59);

      // may already be a moment, but here we make sure
      let momentStart = GlobalService.getNow();
      let momentEnd = moment(end);

      if (momentStart.isSame(momentEnd, 'day')) {
        time = momentEnd.format('[today at] h:mmA');
      } else if (momentStart.clone().add(1, 'days').isSame(momentEnd, 'day')) {
        time = momentEnd.format('[tomorrow at] h:mmA');
      } else {
        time = momentEnd.format('dddd, MMMM D [at] h:mmA');
      }

      if (basket) {
        // description = 'Scheduled for ' + basket.deliverymode;
        if (basket.deliverymode === 'pickup') {
          description = 'Scheduled for carryout';
        }
        if (basket.deliverymode === 'curbside') {
          description = 'Scheduled for curbside';
        }
        if (basket.deliverymode === 'dispatch') {
          description = 'Scheduled for delivery';
        }
        if (basket.deliverymode === 'dinein') {
          description = 'Scheduled for dine-in';
        }
      }
    }

    if (twoLines) {
      return [description, time];
    } else {
      return description + ' ' + time + '.';
    }
  }

  private static handleNonTransferrableItems(
    appStore: State<IAppStore>,
    appStateActions: AppStateActions,
    location: Location
  ) {
    let itemsnottransferred = appStore.getValue().appState.itemsnottransferred;

    if (itemsnottransferred && itemsnottransferred.length) {
      let msg =
        'Based on the current availability at ' +
        location.storename +
        ', the following items were removed from your basket: ';
      msg += itemsnottransferred.join(', ');

      appStateActions.appendError(msg);
      appStateActions.clearNonTransferable();
      appStateActions.clearUpsellProducts();
    }
  }

  // If this is dispatch, compare previous and new baskets and manually remove products that should be removed
  private static handleManualNonTransferrableItems(
    appStateActions: AppStateActions,
    existingBasket: Basket,
    newBasket: Basket
  ) {
    // Self-Serve, Coke Freestyle items
    // If we are switching to dispatch, Check if there are self-serve drinks that should be removed from the basket
    // There is no metadata to use at this point, so we must do it by name
    if (newBasket.deliverymode === 'dispatch') {
      const removedProducts = this.removeProductByModifier(
        appStateActions,
        newBasket,
        'self-serve'
      );
      // Alert the user that items were removed
      if (removedProducts.length > 0) {
        let msg =
          'Based on the current availability at Wingstop, the following items were removed from your basket: ';
        msg += removedProducts.join(', ');
        appStateActions.appendError(msg);
      }
    }
  }

  // Search product choices for a sepcific modifer option by name and remove it
  // Return an array of products that were removed
  private static removeProductByModifier(
    appStateActions: AppStateActions,
    basket: Basket,
    modifier: string
  ): string[] {
    const removedItems: string[] = [];
    if (Array.isArray(basket.products) && basket.products.length > 0) {
      basket.products.forEach((product) => {
        if (Array.isArray(product.choices) && product.choices.length > 0) {
          product.choices.forEach((choice) => {
            if (choice.name.toLowerCase().includes(modifier.toLowerCase())) {
              removedItems.push(product.name);
              appStateActions.deleteFromBasket(product);
            }
          });
        }
      });
    }
    return removedItems;
  }

  public static beginOrderAt(
    router: Router,
    appStateActions: AppStateActions,
    appStore: State<IAppStore>,
    productService: ProductService,
    location: Location,
    address?: DeliveryAddress,
    existingBasket?: Basket,
    product?: string,
    curbside?: boolean,
    dinein?: boolean,
    redirectTo?: RedirectTo,
    pilotService?: PilotService
  ) {
    // clear any old errors
    appStateActions.clearErrors();

    appStateActions.setLocation(location);

    let strErr =
      'Changing your location has reset your order time.  Please review the order time or set a future time for the new location.';

    const isDigitalMenuOrder =
      appStore.getValue().appState.startOrderSource === 'digital-menu';
    const selectedMenu = appStore.getValue().appState.selectedMenu;
    const menuProduct =
      selectedMenu && product
        ? productService.findProductByProperty('slug', product, selectedMenu)
        : undefined;
    const category =
      selectedMenu && product
        ? productService.findProductCategoryById('slug', product, selectedMenu)
        : undefined;
    const deliveryMode = () => {
      if (dinein) {
        return Basket.MODE_DINEIN;
      } else {
        return address ? Basket.MODE_DISPATCH : Basket.MODE_PICKUP;
      }
    };

    const redirect = (redirectTo?: RedirectTo) => {
      let route =
        existingBasket && existingBasket.products.length > 0
          ? ['/order/my-bag']
          : ['/menu'];

      if (redirectTo === RedirectTo.MENU) {
        route = ['/location', location.slug, 'menu'];
      } else if (redirectTo === RedirectTo.PDP) {
        if (menuProduct && category) {
          route = [
            '/location',
            location.slug,
            'menu',
            category.slug,
            menuProduct.slug,
          ];
        } else {
          route = ['/location', location.slug, 'menu'];
        }
      }

      if (redirectTo !== RedirectTo.NONE) {
        router.navigate(route, {
          queryParamsHandling: 'preserve',
        });
      }
    };

    let transferSuccess = () => {
      redirect(redirectTo);

      appStateActions.getBasket().then((newBasket) => {
        // Check if we need to manually remove special items during the transfer
        this.handleManualNonTransferrableItems(
          appStateActions,
          existingBasket,
          (newBasket as any).payload
        );

        // append the "these items weren't transferred" error (if needed)
        this.handleNonTransferrableItems(appStore, appStateActions, location);
        appStateActions.openModalWithErrors(
          'Heads Up',
          'The following updates have been made:',
          'Got It'
        );
        appStateActions.clearErrors();
      });
    };

    // if new location is the same as the old
    if (existingBasket && existingBasket.vendorid === location.id) {
      // change the handoff as needed
      if (curbside) {
        return appStateActions.makeCurbside().then(transferSuccess);
      }
      if (dinein) {
        return appStateActions.makeDinein().then(transferSuccess);
      }
      if (!address) {
        return appStateActions.makePickup().then(transferSuccess);
      } else {
        return appStateActions.makeDispatch(address).then(transferSuccess);
      }
    }

    // if basket already exists, do a basket transfer
    if (
      existingBasket &&
      !existingBasket.isEmpty() &&
      !appStore.getValue().appState.selectedLocation.isDarkKitchen()
    ) {
      return appStateActions
        .transferBasket(location, deliveryMode(), address)
        .then(() => {
          if (!existingBasket.inASAPMode()) {
            // append the "order reset" error
            appStateActions.appendError(strErr);
            return appStateActions.setAsap().then(transferSuccess);
          }
          return appStateActions.getBasket().then((basket) => {
            transferSuccess();
            if (
              existingBasket.products.length > 0 &&
              (basket as any).payload.products.length === 0
            ) {
              const removedItems: string[] = [];
              existingBasket.products.forEach((cv) => {
                removedItems.push(cv.name);
              });

              // If this is a Pilot Location we do not want to show the transfer error
              if (
                pilotService &&
                pilotService.getPilotRedirectEnabled() &&
                pilotService.isPilotLocationAndShouldRedirect(location)
              ) {
                pilotService.redirectToNgfeWebsite(null, location);
              } else {
                appStateActions.openAlertModalWith(
                  'Heads up',
                  'Based on the current availability at Wingstop, the following items were removed from your basket: ' +
                  removedItems.join(', '),
                  'Got It',
                  'The following updates have been made:',
                  'Close',
                  null,
                  false,
                  null
                );
              }
            }
          });
        });
    }

    // make a new basket
    return appStateActions
      .createBasket(location, null, deliveryMode(), address)
      .then(() => {
        // If digital menu order don't redirect
        if (menuProduct && category && !isDigitalMenuOrder) {
          // Redirect to PDP
          appStateActions.setProductRedirect(
            new ProductRedirect({
              category_slug: category.slug,
              product_slug: menuProduct.slug,
            })
          );
        }

        redirect(redirectTo);

        this.logGaEvent({
          event: 'start_new_order',
        });

        return Promise.resolve();
      })
      .catch((e) => {
        console.error(`Could not create basket at ${location.name}`);
        throw e;
      });
  }
}
