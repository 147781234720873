import { Injectable } from '@angular/core';

import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { TimeWanted } from '@wingstop/models/time-wanted.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public deliveryMode: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private appStateActions: AppStateActions) {}

  updateDeliveryMode(mode: string) {
    this.deliveryMode.next(mode);
  }
}
