import { Injectable } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { environment } from '../environments/environment';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { InteractionStudioService } from './interaction-studio.service';
import { UserIdentityService } from './user-identity-service';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AnalyticsService } from './analytics.service.service';
import { Subscription, takeUntil } from 'rxjs';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { Location } from '@wingstop/models/location/location.model';
import { Basket } from '@wingstop/models/basket.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';
import {
  IPingAuthentication,
  IPingAuthenticationProfile,
} from '@wingstop/models/pilot/ping-authentication.model';

import { GlobalService } from './global.services';
import moment from 'moment-mini';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';

declare var window: any;
declare const google: any;
const AUTH_COOKIE = environment.pingAuthCookieName;
const PROFILE_COOKIE = environment.pingProfileCookieName;
const COOKIE_DOMAIN = environment.pingCookieDomain;

@Injectable({
  providedIn: 'root',
})
export class PilotService {
  public subscriptions: Subscription[] = [];
  private authentication: Authentication = null;
  private pilotProgram: IPilotProgram = null;
  private pilotProgramUser: IPilotProgramUser = null;
  private basket: Basket = null;
  private qaDeletePingDetailsAlreadyRan: boolean;

  constructor(
    private router: Router,
    private appStateSelectors: AppStateSelectors,
    private state: State<IAppStore>,
    private appStateActions: AppStateActions,
    private authService: SocialAuthService,
    private interactionStudioService: InteractionStudioService,
    private userIdentityService: UserIdentityService,
    private analyticsService: AnalyticsService,
    private store: Store<IAppStore | any>
  ) {
    this.getAuthData();
    this.setFeatureFlags();
    this.setPilotUserDetails();
    this.getBasket();
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }

  setFeatureFlags() {
    this.subscriptions.push(
      this.appStateSelectors.pilotProgram.subscribe((value: IPilotProgram) => {
        this.pilotProgram = value;
        if (environment.envName !== 'prod') {
          this.logFeatureFlagsDetails();
        }
        if (this.getPilotForceLogoutEnabled()) {
          this.handleUserForceLogout();
        }
      })
    );
  }

  // Used for specific users to handle redirect scenarios
  setPilotUserDetails() {
    this.subscriptions.push(
      this.appStateSelectors.pilotProgramUser.subscribe(
        (value: IPilotProgramUser) => {
          this.pilotProgramUser = value;
        }
      )
    );
  }

  // Log the Feature Flags to help QA and dev debugging
  logFeatureFlagsDetails() {
    if (environment.envName !== 'prod') {
      const pilotProgramEnabled =
        this.pilotProgram?.programEnabled ?? 'undefined';
      const pilotForceLogoutEnabled =
        this.pilotProgram?.forceLogoutEnabled ?? 'undefined';
      const pilotRedirectEnabled =
        this.pilotProgram?.redirectEnabled ?? 'undefined';

      this.logHelper('pilotProgramEnabled Feature Flag', pilotProgramEnabled);
      this.logHelper(
        'pilotForceLogoutEnabled Feature Flag',
        pilotForceLogoutEnabled
      );
      this.logHelper('pilotRedirectEnabled Feature Flag', pilotRedirectEnabled);
    }
  }

  getAuthData() {
    this.subscriptions.push(
      this.appStateSelectors.authentication.subscribe(
        (authentication: Authentication) => {
          this.authentication = authentication;

          // Set Cookie Data
          if (this.authentication) {
            if (environment.envName !== 'prod') {
              this.handleQaPingTesting();
            }
            this.setPingCookieData();
          } else {
            this.handleRemoveCookies();
          }
        }
      )
    );
  }

  getBasket() {
    this.subscriptions.push(
      this.appStateSelectors.basket.subscribe((value: Basket) => {
        this.basket = value;
      })
    );
  }

  getPilotProgramEnabled(): boolean {
    return this.pilotProgram.programEnabled;
  }

  getPilotForceLogoutEnabled(): boolean {
    return (
      this.getPilotProgramEnabled() && this.pilotProgram.forceLogoutEnabled
    );
  }

  getPilotRedirectEnabled(): boolean {
    return this.getPilotProgramEnabled() && this.pilotProgram.redirectEnabled;
  }

  isPilotLocation(location: Location): boolean {
    // return true; // For testing purposes
    if (!location) {
      return false;
    }
    return location.isPilotLocation();
  }

  // Check if we have a session related to an ngfe_order param
  // A customer is then allowed to remain on the website for 24 hours
  ngfeOrderSessionIsSetAndValid(): boolean {
    const haveNgfeOrder = this.pilotProgramUser?.ngfeOrderSession ?? false;
    if (haveNgfeOrder) {
      // Check if the ngfeOrderSession is valid
      const ngfeSessionExpiresDate = this.pilotProgramUser
        ?.ngfeOrderSessionExpires
        ? moment(this.pilotProgramUser?.ngfeOrderSessionExpires)
        : null;

      if (ngfeSessionExpiresDate) {
        const now = GlobalService.getNow();
        const sessionIsValid = ngfeSessionExpiresDate.isAfter(now)
          ? true
          : false;

        if (environment.envName !== 'prod') {
          const duration = moment.duration(ngfeSessionExpiresDate.diff(now));
          this.logHelper('ngfeOrderSessionIsValid', sessionIsValid);
          this.logHelper(
            'ngfeOrderSessionExpires',
            duration.hours() +
              ' hours ' +
              duration.minutes() +
              ' minutes ' +
              duration.seconds() +
              ' seconds'
          );
        }

        // If the session isn't valid then we should clear the session state
        if (!sessionIsValid) {
          this.logHelper('ngfe_order ession expired. Clearing session');

          // Set the data in the store
          const pilotProgramUser: IPilotProgramUser = {
            ngfeOrderSession: null,
            ngfeOrderSessionExpires: null,
          };
          this.appStateActions.setPilotProgramUser(pilotProgramUser);
        }
        return sessionIsValid;
      }
    }
    return false;
  }

  // Helper method used in canActivate guard to determine if we should redirect a customer away from Menu & PDP Pages
  // If the program redirects are enabled
  // and if the customer does NOT have any products in their basket (or no basket created at all)
  // and if the location is a pilot location
  // and if the customer does NOT have a valid ngfe_order session
  isPilotLocationAndShouldRedirect(location: Location): boolean {
    return (
      this.getPilotRedirectEnabled() &&
      (!this.basket || this.basket.isEmpty()) &&
      location &&
      this.isPilotLocation(location) &&
      !this.ngfeOrderSessionIsSetAndValid()
    );
  }

  // Helper method to determine if we have a 'ngfe_auth' param in the URL
  routeHasNgfeAuth(route?: ActivatedRouteSnapshot): boolean {
    if (!route) {
      route = this.router.routerState.snapshot.root;
    }

    // Doesn't work well because we get an empty string that is falsy
    // const ngfe_auth = route.queryParams['ngfe_auth'];
    // Instead check if the param exists in the URL
    const ngfe_auth = Object.keys(route.queryParams).includes('ngfe_auth');

    this.logHelper('ngfe_auth exists', ngfe_auth);
    return ngfe_auth;
  }

  // Helper method to determine if we have a 'ngfe_order' param in the URL
  handleRouteHasNgfeOrder(route?: ActivatedRouteSnapshot): void {
    if (!route) {
      route = this.router.routerState.snapshot.root;
    }
    const ngfe_order = Object.keys(route.queryParams).includes('ngfe_order');

    if (ngfe_order) {
      this.logHelper('ngfe_order param exists', ngfe_order);
      const now = GlobalService.getNow();
      const expiresDate = now.add(24, 'hours');
      // const expiresDate = now.add(1, 'minutes'); // For Testing

      // Set the data in the store
      const pilotProgramUser: IPilotProgramUser = {
        ngfeOrderSession: true,
        ngfeOrderSessionExpires: expiresDate,
      };
      this.appStateActions.setPilotProgramUser(pilotProgramUser);
    }
  }

  // Helper method to determine if we have a 'ngfe_url' param in the URL and return it
  routeHasNgfeUrl(route?: ActivatedRouteSnapshot): string | null {
    if (!route) {
      route = this.router.routerState.snapshot.root;
    }

    const ngfe_url = route.queryParams['ngfe_url']
      ? decodeURIComponent(route.queryParams['ngfe_url'])
      : null;

    if (ngfe_url) {
      this.logHelper('ngfe_url exists', ngfe_url);
    }

    return ngfe_url;
  }

  // Used for all redirect to NGFE scenarios
  redirectToNgfeWebsite(url?: string, location?: Location): Promise<boolean> {
    return new Promise(async (resolve) => {
      const params = [];
      const ngfeSso = this.authentication?.provider?.toLowerCase();
      const ngfeUrl = this.routeHasNgfeUrl();
      let deliveryAddress: any = null;
      const redeem: any = this.state.getValue().appState.cmsOfferRedeemCode;
      //const redeem: any = 'FREEFRIES' // For testing purposes

      let handOffMode = null;

      // Determine the handoff mode
      if (this.basket && this.basket.isPickup()) {
        handOffMode = 'carryout';
      } else if (this.basket && this.basket.isDispatch()) {
        handOffMode = 'delivery';
        try {
          const detailedAddress: UserDeliveryAddress =
            this.state.getValue().appState.detailedBasketDeliveryAddress;
          if (detailedAddress) {
            deliveryAddress = btoa(
              JSON.stringify({
                streetAddress: detailedAddress.streetaddress,
                city: detailedAddress.city,
                state: detailedAddress.state,
                postalCode: detailedAddress.zipcode,
                country: detailedAddress.country,
              })
            );
          }
        } catch (e) {
          if (environment.envName !== 'prod') {
            console.error(e);
          }
        }
      }

      // If we have a location passed in then we are redirecting to a specific location menu page
      if (location) {
        const locationSlug = location.slug;
        url = `/location/${locationSlug}/menu`;
        this.logHelper('Location Set', url);
      }

      // If we have the ngfeUrl then update our URL to use it
      // Intentionally overrite a location URL because NGFE is explicitly telling us where to go
      if (ngfeUrl) {
        url = ngfeUrl;
        this.logHelper('ngfe_url', ngfeUrl);
      }

      // Apply Params to the URL
      // Append the ngfe_sso param if we have it
      if (ngfeSso) {
        this.logHelper('User has SSO Session', ngfeSso);
        params.push(`ngfe_sso=${ngfeSso}`);
      }

      // Append the handoff_mode param if we have it
      if (handOffMode) {
        this.logHelper('Handoff Mode', handOffMode);
        params.push(`handoffMode=${handOffMode}`);
      }

      // Append the deliveryAddress param if we have it
      if (deliveryAddress) {
        this.logHelper('Delivery Address', deliveryAddress);
        params.push(`deliveryAddress=${deliveryAddress}`);
      }

      // Append the redeem param if we have it
      if (redeem) {
        this.logHelper('Redeem', redeem);
        params.push(`redeem=${redeem}`);
      }

      //Append mcp param if anonymousid exists
      const userId = window?.ws?.user?.anonymousid;
      if (userId) {
        this.logHelper('mcpRef', userId);
        params.push(`mcpRef=${userId}`);
      }

      const paramString =
        params.length > 0
          ? (ngfeUrl && ngfeUrl.includes('?') ? '&' : '?') + params.join('&')
          : '';

      const pilotRedirectHost = this.getPilotBaseUrlPerEnvironment();

      const redirectUrl = url
        ? `${pilotRedirectHost}${url}${paramString}`
        : `${pilotRedirectHost}${paramString}`;

      this.logHelper('Redirecting to NGFE', redirectUrl);

      const win = window.open(redirectUrl, '_self');
      if (win) {
        win.focus();
        // Delay the resolve so that we don't have URL changes flashing to home before redirect
        await setTimeout(() => {
          resolve(true);
        }, 1000);
      } else {
        resolve(false);
      }
    });
  }

  getPilotBaseUrlPerEnvironment(): string {
    if (environment.envName !== 'prod') {
      const hostname = window.location.hostname;
      switch (hostname) {
        case environment?.hostname2:
          return environment.pilotUrl2;
        case environment?.hostname3:
          return environment.pilotUrl3;
        default:
          break;
      }
    }
    return environment.pilotUrl;
  }

  handleNgfeAuthRedirect(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.getPilotRedirectEnabled() || !this.routeHasNgfeAuth()) {
        resolve(false);
      } else {
        this.redirectToNgfeWebsite()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (environment.envName !== 'prod') {
              console.error(error);
            }
            resolve(false);
          });
      }
    });
  }

  logHelper(message: string, value: any = null): void {
    if (environment.envName !== 'prod') {
      if (value !== null) {
        console.log(`[Pilot] ${message}:`, value);
      } else {
        console.log(`[Pilot] ${message}`);
      }
    }
  }

  googleSignOut(): void {
    google.accounts.id.disableAutoSelect();
  }

  // Used in the unauthguard service
  // Return true if we need to allow the customer to remain on the Sign Up page
  // Return false if program isn't enabled or we don't have a ngfe_auth param in the URL
  // In a true scenario, this will then allow the PilotAuthenticationResolver to run and perform the logout we need
  async allowCustomerToRemainOnSignUpRoute(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> {
    // If the api takes over 1 second to respond, then we will just return false
    const timeoutPromise = new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(false);
      }, 1000);
    });

    // undefined is initial state.
    // Keep trying until we get a real value (true or false)
    const redirectEnabled = await Promise.race([
      new Promise(async (resolve) => {
        while (this.pilotProgram.programEnabled === undefined) {
          await new Promise((innerResolve) => setTimeout(innerResolve, 500));
        }
        resolve(this.getPilotRedirectEnabled());
      }),
      timeoutPromise,
    ]).catch(() => false);

    if (redirectEnabled && this.routeHasNgfeAuth(route)) {
      return true;
    }

    return false;
  }

  // If there is a ngfe_auth param in the URL, we need to log the customer out
  // This is a scenario where NGFE is redirecting the customer back to the app for us to log them in
  // This should only run if the pilot program is enabled and rediret is enabled
  handleNgfeAuthLogout(route: ActivatedRouteSnapshot): void {
    if (!this.getPilotRedirectEnabled() || !this.routeHasNgfeAuth(route)) {
      return;
    }

    if (!this.authentication) {
      this.logHelper('Customer is logged out');
      return;
    }

    this.logHelper('Customer is logged in', this.authentication.emailaddress);

    if (this.getPilotProgramEnabled()) {
      this.logHelper('Logging out customer');

      this.interactionStudioService.interactionStudioWsLogout();
      this.appStateActions.userLoggedOut().then((r) => {
        this.analyticsService.logGaEvent({
          event: 'logout',
        });
        this.analyticsService.logGaEvent({
          event: 'account_click_option',
          account_option_value: 'Logout',
        });
        this.analyticsService.logGaEvent({
          user_id: null,
        });
        this.googleSignOut();
        this.authService.signOut().catch((e: any) => {
          // Don't log any errors
          // console.log(e);
        });
        this.userIdentityService.setSignupViaSecretMenu(null);

        this.router.navigate([], {
          queryParamsHandling: 'preserve',
        });
      });
    }
  }

  shouldForceLogoutUser(): boolean {
    const forceLogoutEnabled = this.getPilotForceLogoutEnabled();
    const pingProfile =
      this.authentication &&
      this.authentication.nomnom &&
      this.authentication.nomnom.ping &&
      this.authentication.nomnom.ping.profile;

    const missingPingData =
      !pingProfile ||
      (pingProfile && !pingProfile?.ssoUser && !pingProfile?.syncAuthComplete);

    const lastForceLogoutDate =
      this.state.getValue().appState.lastForceLogoutDate ?? false;

    let timeExpired = false;

    const routeHasNgfeAuth =
      this.routeHasNgfeAuth() || window.location.href.includes('ngfe_auth');

    if (environment.envName !== 'prod') {
      this.logHelper('Have Logged in User', this.authentication ? true : false);
      this.logHelper('Have ping profile', pingProfile ? true : false);
      this.logHelper(
        'Have syncAuthComplete',
        pingProfile?.syncAuthComplete ? true : false
      );
      this.logHelper('Have ssoUser', pingProfile?.ssoUser ? true : false);
      this.logHelper('Missing Ping Data', missingPingData);
    }

    // Check if the last forced logout is within the last 24 hours
    if (lastForceLogoutDate) {
      const logoutExpires = moment(lastForceLogoutDate).add(24, 'hours');

      if (logoutExpires) {
        const now = GlobalService.getNow();
        timeExpired = logoutExpires.isBefore(now) ? true : false;

        if (environment.envName !== 'prod') {
          const duration = moment.duration(logoutExpires.diff(now));
          this.logHelper(
            'Force Logout Expires',
            duration.hours() +
              ' hours ' +
              duration.minutes() +
              ' minutes ' +
              duration.seconds() +
              ' seconds'
          );
          this.logHelper('24 Hours expired', timeExpired);
        }
      }
    } else {
      // This is a customer that has never been forced logged out
      // We need to log them out so override the timeExpired check (because technically it has expired)
      timeExpired = true;
    }
    if (
      this.authentication &&
      forceLogoutEnabled &&
      missingPingData &&
      timeExpired &&
      !routeHasNgfeAuth
    ) {
      return true;
    }
    return false;
  }

  async handleUserForceLogout(e?: any): Promise<any> {
    try {
      if (this.shouldForceLogoutUser()) {
        this.logHelper('Should force logout user');

        try {
          await this.appStateActions.userLoggedOut();
          const now = GlobalService.getNow();
          this.store.dispatch({
            type: AppStateActions.SET_LAST_FORCE_LOGOUT_DATE,
            payload: now,
          });
          return this.router.navigate(['/account/login'], {
            queryParams: { sessionExpired: true },
          });
        } catch (e) {
          this.logHelper(e);
        }
      } else {
        this.logHelper('Do not force logout user');
      }
    } catch (e) {
      this.logHelper(e);
    }
  }

  handleRemoveCookies(): void {
    // Clear cookie data if we have it
    if (
      document.cookie.match(
        new RegExp('^(.*;)?\\s*' + AUTH_COOKIE + '\\s*=\\s*[^;]+(.*)?$')
      )
    ) {
      this.deletePingCookieData();
    }
  }

  // Helps QA for testing specific scenarios
  // Looks for 3 params and deletes data as needed
  handleQaPingTesting(): void {
    if (environment.envName !== 'prod' && !this.qaDeletePingDetailsAlreadyRan) {
      const route = this.router.routerState.snapshot.root;

      let authData = this.authentication;
      let deletionPerformed = false;
      if (window.location.href.includes('pingQaDeletePingObject')) {
        delete authData.nomnom.ping;
        this.logHelper('Deleted Ping Object');
        deletionPerformed = true;
      }

      if (window.location.href.includes('pingQaDeletePingSyncAuthComplete')) {
        delete authData.nomnom.ping.profile.syncAuthComplete;
        this.logHelper('Deleted syncAuthComplete');
        deletionPerformed = true;
      }

      if (window.location.href.includes('pingQaDeletePingSsoUser')) {
        delete authData.nomnom.ping.profile.ssoUser;
        this.logHelper('Deleted ssoUser');
        deletionPerformed = true;
      }

      if (deletionPerformed) {
        this.qaDeletePingDetailsAlreadyRan = true;
        const authDispatch = this.store.dispatch({
          type: AppStateActions.AUTHENTICATE,
          payload: { ...authData },
        });
      }
    }
  }

  // Set cookie data from Ping
  setPingCookieData(): void {
    // If we don't have a ping object and token, we can't do anything
    // This is most likely a customer logged in from before Ping existed
    // or something went wrong with Ping Login
    if (!this.authentication?.nomnom?.ping?.access_token) {
      return;
    }
    // Wrap in a try/catch in case there is a weird data interface mismatch going on from Ping
    try {
      this.logHelper('Setting Ping cookie data');

      const {
        id_token,
        access_token,
        refresh_token,
        token_type,
        scope,
        profile,
        expires_at,
      } = this.authentication.nomnom.ping as IPingAuthentication;

      const authCookieValues = {
        id_token,
        access_token,
        refresh_token,
        token_type,
        scope,
        expires_at,
      };

      const profileCookieValues = {
        profile,
      };

      // Auth Cookie
      this.createCookie(AUTH_COOKIE, authCookieValues, expires_at);

      // Profile Cookie
      this.createCookie(PROFILE_COOKIE, profileCookieValues, expires_at);
    } catch (e) {
      this.logHelper('Error setting cookie data', e);
    }
  }

  private createCookie(name: string, value: object, expires: string): void {
    // Cookie name key
    let cookieString = `${name}=`;

    // Add the value to the cookie
    cookieString += `${JSON.stringify(value)}; `;

    // Add the expiry date to the cookie
    const cookieExpiry = new Date(expires);
    cookieString += `expires=${cookieExpiry.toUTCString()}; `;

    // Restrict the cookie to only be accessible by the specified domains (wingstop.com and subdomains)
    cookieString += `domain=.${COOKIE_DOMAIN}; `;

    // Set the Cookie path to the root of the domain
    cookieString += `path=/; `;

    // Add Secure to the cookie for extra security
    cookieString += `Secure; `;

    // Write the cookie
    document.cookie = cookieString;

    this.logHelper('Cookie data set', name);
  }

  deletePingCookieData(): void {
    this.deleteCookie(AUTH_COOKIE);
    this.deleteCookie(PROFILE_COOKIE);
  }

  // Delete a cookie by name
  private deleteCookie(name: string): void {
    this.logHelper('Deleting cookie', name);
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.${COOKIE_DOMAIN}; path=/;`;
  }

  // This API call returns an object with a payload. So returning the payload for the Location specific data
  public async getLocation(
    route: ActivatedRouteSnapshot
  ): Promise<Location | any> {
    const routeParams = route?.params;
    if (routeParams?.slug != null) {
      return this.appStateActions
        .getLocation(routeParams.slug)
        .then(async (location: any) => {
          return location?.payload;
        });
    } else {
      return false;
    }
  }

  async featuresSet(): Promise<void> {
    // If the api takes over 1 second to respond, then we will just return false
    const timeoutPromise = new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(false);
      }, 1000);
    });

    // undefined is initial state.
    // Keep trying until we get a real value (true or false)
    const redirectEnabled = await Promise.race([
      new Promise<boolean>(async (resolve) => {
        while (this.pilotProgram.programEnabled === undefined) {
          await new Promise((innerResolve) => setTimeout(innerResolve, 500));
        }
        resolve(this.getPilotRedirectEnabled());
      }),
      timeoutPromise,
    ]).catch(() => false);
    return;
  }

  haveBasketWithItems(): boolean {
    return this.basket && !this.basket.isEmpty();
  }
}
