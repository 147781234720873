import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';

@Injectable()
export class OrderIdGuardService implements CanActivate {
  constructor(public router: Router, private store: State<IAppStore>) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const orderId = route.queryParams['orderid'];
    if (orderId) {
      this.router.navigate([`/order/recent/order-details/${orderId}`]);
      return false;
    }
    return true;
  }
}
