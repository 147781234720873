import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { PilotService } from './pilot.service';

@Injectable()
export class PilotBagGuardService implements CanActivate {
  constructor(
    public router: Router,
    private store: State<IAppStore>,
    private pilotService: PilotService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    try {
      if (this.pilotService.getPilotRedirectEnabled()) {
        const location = this.store.getValue().appState.selectedLocation;

        // This will check for the param and set data.
        // Then the redirect method will use the data to determine how to proceed
        this.pilotService.handleRouteHasNgfeOrder(route);

        // If we have a location set then do some logic
        // If no location is set then this is likely a direct route link and customer doesn't have any data set
        if (location) {
          // Check if we need to redirect based on the Pilot Program
          if (this.pilotService.isPilotLocationAndShouldRedirect(location)) {
            this.pilotService.redirectToNgfeWebsite(null, location);
            return false;
          }
        }
      }
    } catch (error) {
      // Any errors then allow the customer to continue
      return true;
    }
    return true;
  }
}
