import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { State } from '@ngrx/store';

import { IAppStore } from '@wingstop/store/app-store';
import { PilotService } from '../services/pilot.service';

@Injectable()
export class UnauthGuardService implements CanActivate {
  constructor(
    public router: Router,
    private store: State<IAppStore>,
    private pilotService: PilotService
  ) {}

  // pilotService.allowCustomerToRemainOnSignUpRoute() is a unique scenario where we want to allow
  // the customer to remain on the register page.
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      this.store.getValue().appState.authentication !== null &&
      typeof this.store.getValue().appState.authentication !== 'undefined'
    ) {
      const allowed =
        await this.pilotService.allowCustomerToRemainOnSignUpRoute(route);
      if (!allowed) {
        this.router.navigate(['']);
        return false;
      }
    }
    return true;
  }
}
