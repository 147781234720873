import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@wingstop/models/location/location.model';

@Pipe({
  name: 'locationNowOpen',
  standalone: true,
})
export class LocationNowOpenPipe implements PipeTransform {
  transform(location: Location, ...args: unknown[]): boolean {
    return location.isRecentlyOpen();
  }
}
