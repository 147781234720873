import { combineReducers, createStore, Reducer, Store } from 'redux';
import { AppStateReducer } from './app/app-state.reducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AppState } from './app/app-state.model';
import { createFilter } from 'redux-persist-transform-filter';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { ActionReducer, ActionReducerMap, INIT } from '@ngrx/store';
import { FeaturesState, reducersLib } from '@wingstop-inc/ngfe-web';
import { parse, stringify } from 'flatted';
import { devToolsEnhancer } from '@redux-devtools/extension';

export interface IAppStore extends FeaturesState {
  appState?: AppState;
}

export function configureStore(): Promise<Store<IAppStore>> {
  return new Promise((resolve, reject) => {
    try {
      const saveSubsetFilter = createFilter('appState', [
        'authentication',
        'basket',
        'lastSessionOrder',
        'defaultTipAppliedTo',
        'confirmationPageViewed',
        'rememberMe',
        'tipIndex',
        'userTip',
        'defaultTip',
        'cmsOffer',
        'cmsOfferRedeemCode',
        'cmsOfferTimestamp',
        'toolTipSeen',
        'geolocationAllowed',
        'guestCheckoutEmail',
        'currentProductSelections',
        'upsellProducts',
        'isReorder',
        'startOrderSource',
        'roundUpAmount',
        'roundUpSelected',
        'appBannerClosedByUser',
        'userLocale',
        'userHasSeenTheLocaleModal',
        'pilotProgramUser',
        'lastForceLogoutDate',
        'detailedBasketDeliveryAddress',
      ]);

      const rootReducer: Reducer<IAppStore> = combineReducers<IAppStore>({
        appState: AppStateReducer,
        ...reducersLib,
      });

      const rootPersistConfig = {
        key: 'root', // key is required
        storage, // storage is now required
        debug: true,
        blacklist: [] as any,
        transforms: [saveSubsetFilter],
      };

      const reducer = persistReducer(rootPersistConfig, rootReducer);
      const store = createStore(reducer, devToolsEnhancer({}));
      persistStore(store, {}, () => resolve(store));
    } catch (e) {
      reject(e);
    }
  });
}

export const reducers: ActionReducerMap<IAppStore> = {
  appState: AppStateReducer,
  ...reducersLib,
};

export function hydrationMetaReducer(
  reducer: ActionReducer<IAppStore>
): ActionReducer<IAppStore> {
  return (state, action) => {
    const nextState = reducer(state, action);
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('reduxStore');
      const sessionValue = sessionStorage.getItem('sessionRedux');
      if (storageValue) {
        try {
          let both = nextState;
          if (sessionValue) {
            both = merge(nextState, parse(sessionValue));
          }
          return merge(both, parse(storageValue));
        } catch (e) {
          console.log('error parsing state ', e);
          localStorage.removeItem('reduxStore');
        }
      }
    }
    const saveSubsetFilter = {
      // this is the stuff you want to keep persisted in local storage
      appState: [
        'authentication',
        'basket',
        'lastSessionOrder',
        'defaultTipAppliedTo',
        'confirmationPageViewed',
        'rememberMe',
        'tipIndex',
        'userTip',
        'defaultTip',
        'cmsOffer',
        'cmsOfferRedeemCode',
        'cmsOfferTimestamp',
        'toolTipSeen',
        'geolocationAllowed',
        'guestCheckoutEmail',
        'currentProductSelections',
        'upsellProducts',
        'isReorder',
        'startOrderSource',
        'roundUpAmount',
        'roundUpSelected',
        'appBannerClosedByUser',
        'userLocale',
        'userHasSeenTheLocaleModal',
        'pilotProgramUser',
        'lastForceLogoutDate',
        'detailedBasketDeliveryAddress',
      ],
      eventState: <string[]>[],
      localAppState: <string[]>[],
    };

    const saveSubsetBlacklistFilter = [
      'appState.lastSessionOrder.basket.crosssell',
      'appState.lastSessionOrder.basket.nomnom',
      'appState.lastSessionOrder.basket.restaurant',
      'appState.basket.products',
      'appState.basket.restaurant',
      'appState.selectedLocation.menu',
      'appState.basket.crosssell',
      'appState.basket.nomnom',
    ];

    const sessionSaveSubsetFilter = {
      appState: <string[]>[],
    };

    const saveSessionSubsetBlacklistFilter: string[] = [];

    const applyFilter = (subset: any, blacklist: any) => {
      const obj = {};
      const clone = cloneDeep(nextState);
      Object.keys(subset).forEach((key: string) => {
        const pickled = pick(clone[key], subset[key]);
        obj[key] = pickled;
      });
      blacklist.forEach((item: string) => {
        omit(obj, item);
      });
      return obj;
    };
    const stateToSave = applyFilter(
      saveSubsetFilter,
      saveSubsetBlacklistFilter
    );
    localStorage.setItem('reduxStore', stringify(stateToSave));

    const sessionState = applyFilter(
      sessionSaveSubsetFilter,
      saveSessionSubsetBlacklistFilter
    );
    sessionStorage.setItem('sessionRedux', stringify(sessionState));
    return nextState;
  };
}
