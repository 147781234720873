import { Injectable } from '@angular/core';
import { HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { RequestCacheService } from '@wingstop/services/request-cache.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private headers = ['ui-transformer', 'ui-response-transformer'];

  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const cacheId = Math.random().toString(36).substring(7);
          let headers: HttpHeaders = new HttpHeaders();
          headers = headers.append(
            'ui-cache-key',
            req.headers.get('ui-cache-key') || cacheId
          );
          // Carryover headers
          this.headers.forEach((h) => {
            if (req.headers.get(h)) {
              headers = headers.append(h, req.headers.get(h));
            }
          });
          event = event.clone({
            headers: headers,
          });
          this.cache.set(req, event, Number(req.headers.get('ui-cache-ttl')));
        }
        return event;
      })
    );
  }
}
