import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';

@Injectable()
export class GlobalProductGuardService implements CanActivate {
  constructor(public router: Router, private store: State<IAppStore>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.store.getValue().appState.selectedLocation != null) {
      this.router.navigate(
        [
          '/location',
          this.store.getValue().appState.selectedLocation.slug,
          'menu',
          route.params.category,
          route.params.product,
        ],
        { queryParams: route.queryParams }
      );
      return false;
    }
    return true;
  }
}
