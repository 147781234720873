import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';

@Injectable()
export class GlobalMenuGuardService implements CanActivate {
  constructor(public router: Router, private store: State<IAppStore>) {}

  canActivate(): boolean {
    if (
      this.store.getValue().appState.selectedLocation != null &&
      !this.router.url.includes('digital-menu')
    ) {
      this.router.navigate([
        '/location',
        this.store.getValue().appState.selectedLocation.slug,
        'menu',
      ]);
      return false;
    }
    return true;
  }
}
