import { State, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { environment } from '@wingstop/environments/environment';
import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { BasketChoice } from '@wingstop/models/basket/basket-choice.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { BillingAccount } from '@wingstop/models/basket/billing-account.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { ClubModel } from '@wingstop/models/club.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { GeocodeResponse } from '@wingstop/models/geocode-response.model';
import { Location } from '@wingstop/models/location/location.model';
import { Login } from '@wingstop/models/login.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { RememberMe } from '@wingstop/models/login/remember-me.model';
import { SignUp } from '@wingstop/models/login/sign-up.model';
import { UpdateContactOptions } from '@wingstop/models/login/update-contact-options.model';
import { UpdatePassword } from '@wingstop/models/login/update-password.model';
import { UpdateUser } from '@wingstop/models/login/update-user.model';
import { UserBillingAccount } from '@wingstop/models/login/user-billing-account.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { Menu } from '@wingstop/models/menu.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';
import { ProductRedirect } from '@wingstop/models/menu/product-redirect.model';
import {
  UpsellGaTypes,
  UpsellTypes,
} from '@wingstop/models/menu/upsell-product.model';
import { CustomField } from '@wingstop/models/order/custom-field.model';
import { FavoriteOrder } from '@wingstop/models/order/favorite-order.model';
import { PaymentProviders } from '@wingstop/models/order/fiserv-payments.model';
import { LastSessionOrder } from '@wingstop/models/order/last-session-order.model';
import { PaymentTypes } from '@wingstop/models/order/payment-types.model';
import { SSOUser } from '@wingstop/models/SSOUser.model';
import { TimeWanted } from '@wingstop/models/time-wanted.model';
import { ApiService } from '@wingstop/services/api.service';
import { UserIdentityService } from '@wingstop/services/user-identity-service';
import { IAppStore } from '@wingstop/store/app-store';
import { SocialUser } from '@abacritt/angularx-social-login';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';

import moment from 'moment-mini';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';
@Injectable()
export class AppStateActions {
  static SEARCH_LOCATIONS: string = 'SEARCH_LOCATIONS';
  static SET_LOCATION: string = 'SET_LOCATION';
  static SET_ERRORS: string = 'SET_ERRORS';
  static SET_MAP: string = 'SET_MAP';
  static SET_MENU: string = 'SET_MENU';
  static SET_PRODUCT: string = 'SET_PRODUCT';
  static SET_PRODUCT_REDIRECT: string = 'SET_PRODUCT_REDIRECT';
  static SET_BASKET: string = 'SET_BASKET';
  static SET_GLOBAL_MENU: string = 'SET_GLOBAL_MENU';
  static SET_DETAILED_BASKET_DELIVERY_ADDRESS: string =
    'SET_DETAILED_BASKET_DELIVERY_ADDRESS';
  static ADD_RECENT: string = 'ADD_RECENT';
  static AUTHENTICATE: string = 'AUTHENTICATE';
  static AUTHENTICATE_GOOGLE: string = 'AUTHENTICATE_GOOGLE';
  static AUTHENTICATE_FACEBOOK: string = 'AUTHENTICATE_FACEBOOK';
  static RECENT: string = 'RECENT';
  static USER_ADDRESSES: string = 'USER_ADDRESSES';
  static USER_BILLING_ACCOUNTS: string = 'USER_BILLING_ACCOUNTS';
  static USER_FAVORITE_LOCATIONS: string = 'USER_FAVORITE_LOCATIONS';
  static USER_CONTACT_OPTIONS: string = 'USER_CONTACT_OPTIONS';
  static USER_CONTACT_DETAILS: string = 'USER_CONTACT_DETAILS';
  static OPEN_ALERT_MODAL: string = 'OPEN_ALERT_MODAL';
  static CLOSE_ALERT_MODAL: string = 'CLOSE_ALERT_MODAL';
  static USER_LOGGED_OUT: string = 'USER_LOGGED_OUT';
  static ADD_GUEST_ADDRESS: string = 'ADD_GUEST_ADDRESS';
  static SET_BASKET_HANDOFF_MODE: string = 'SET_BASKET_HANDOFF_MODE';
  static SET_LAST_SESSION_ORDER: string = 'SET_LAST_SESSION_ORDER';
  static FAVORITE_ORDERS: string = 'FAVORITE_ORDERS';
  static BASKET_TRANSFER: string = 'BASKET_TRANSFER';
  static CLEAR_NON_TRANSFERABLE: string = 'CLEAR_NON_TRANSFERABLE';
  static CONFIRM_ALERT: string = 'CONFIRM_ALERT';
  static DISCARD_ALERT: string = 'DISCARD_ALERT';
  static SET_FAVORITE_NAME: string = 'SET_FAVORITE_NAME';
  static SET_CLUB_FORM: string = 'SET_CLUB_FORM';
  static SET_REDIRECT: string = 'SET_REDIRECT';
  static MARK_BASKET_TIP: string = 'MARK_BASKET_TIP';
  static CONFIRMATION_PAGE_VIEWED: string = 'CONFIRMATION_PAGE_VIEWED';
  static SET_REMEMBER_ME: string = 'SET_REMEMBER_ME';
  static SET_DEFAULT_TIP: string = 'SET_DEFAULT_TIP';
  static SET_USER_TIP: string = 'SET_USER_TIP';
  static SET_TIP_INDEX: string = 'SET_TIP_INDEX';
  static SET_CMS_OFFER: string = 'SET_CMS_OFFER';
  static SET_CMS_OFFER_REDEEM_CODE: string = 'SET_CMS_OFFER_REDEEM_CODE';
  static SET_CMS_OFFER_TIMESTAMP = 'SET_CMS_OFFER_TIMESTAMP';
  static SET_TOOLTIP_SEEN = 'SET_TOOLTIP_SEEN';
  static GEOLOCATION_ALLOWED = 'GEOLOCATION_ALLOWED';
  static GUEST_CHECKOUT_EMAIL = 'GUEST_CHECKOUT_EMAIL';
  static SET_CURRENT_PRODUCT_SELECTIONS = 'SET_CURRENT_PRODUCT_SELECTIONS';
  static ADD_UPSELL_PRODUCT = 'ADD_UPSELL_PRODUCT';
  static GET_UPSELL_PRODUCT = 'GET_UPSELL_PRODUCT';
  static REMOVE_UPSELL_PRODUCT = 'REMOVE_UPSELL_PRODUCT';
  static CLEAR_UPSELL_PRODUCTS = 'CLEAR_UPSELL_PRODUCTS';
  static SET_CREATE_USER_ERROR = 'SET_CREATE_USER_ERROR';
  static SET_SIGNUP_SOURCE = 'SET_SIGNUP_SOURCE';
  static USER_HAS_SEEN_LOCALE_MODAL = 'USER_HAS_SEEN_LOCALE_MODAL';
  static SET_CMS_FEATURE_FLAGS = 'SET_CMS_FEATURE_FLAGS';
  static SET_SECRET_MENU_FEATURE_FLAG = 'SET_SECRET_MENU_FEATURE_FLAG';
  static SET_USER_REGISTRATION_FEATURE_FLAG = 'SET_SHOW_USER_REGISTRATION';
  static SET_ROUND_UP_DONATION_FEATURE_FLAG =
    'SET_ROUND_UP_DONATION_FEATURE_FLAG';
  static SET_USER_FIRST_THREE_MONTHS = 'SET_USER_FIRST_THREE_MONTHS';
  static SET_IS_DIGITAL_MENU = 'SET_IS_DIGITAL_MENU';
  static SET_START_ORDER_SOURCE = 'SET_START_ORDER_SOURCE';
  static SET_IS_REORDER = 'SET_IS_REORDER';
  static SET_DIGITAL_MENU_SCROLL_TO = 'SET_DIGITAL_MENU_SCROLL_TO';
  static SET_DIGITAL_MENU_LOADED = 'SET_DIGITAL_MENU_LOADED';
  static SET_SIGNUP_VIA_SECRET_MENU = 'SET_SIGNUP_VIA_SECRET_MENU';
  static SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER';
  static SET_FISERV_USER = 'SET_FISERV_USER';
  static SET_FISERV_SESSION = 'SET_FISERV_SESSION';
  static SET_LOCATION_SUPPORTS_PREPAID = 'SET_LOCATION_SUPPORTS_PREPAID';
  static SET_FISERV_IFRAME_RESPONSE = 'SET_FISERV_IFRAME_RESPONSE';
  static SET_IN_SUBMISSION_FLOW = 'SET_IN_SUBMISSION_FLOW';
  static SET_ROUND_UP_SELECTED = 'SET_ROUND_UP_SELECTED';
  static SET_ROUND_UP_AMOUNT = 'SET_ROUND_UP_AMOUNT';
  static SET_COKE_FREESTYLE = 'SET_COKE_FREESTYLE';
  static SET_COKE_FREESTYLE_FEATURE_FLAG = 'SET_COKE_FREESTYLE_FEATURE_FLAG';
  static SET_APPLE_PAY_FEATURE_FLAG = 'SET_APPLE_PAY_FEATURE_FLAG';
  static SET_SHOW_APP_BANNER = 'SET_SHOW_APP_BANNER';
  static SET_APP_BANNER_CLOSED_BY_USER = 'SET_APP_BANNER_CLOSED_BY_USER';
  static SET_APP_DOWNLOAD_BANNER_FEATURE_FLAG =
    'SET_APP_DOWNLOAD_BANNER_FEATURE_FLAG';
  static SET_USER_LOCALE = 'SET_USER_LOCALE';
  static SET_S3_SEO_METADATA = 'SET_S3_SEO_METADATA';
  static SET_PILOT_PROGRAM_FEATURE_FLAGS = 'SET_PILOT_PROGRAM_FEATURE_FLAGS';
  static SET_PILOT_PROGRAM_USER = 'SET_PILOT_PROGRAM_USER';
  static SET_LAST_FORCE_LOGOUT_DATE = 'SET_LAST_FORCE_LOGOUT_DATE';

  constructor(
    private api: ApiService,
    private userIdentityService: UserIdentityService,
    protected store: Store<IAppStore | any>,
    protected state: State<IAppStore | any>
  ) {}

  setCreateUserError(error: any) {
    return this.store.dispatch({
      type: AppStateActions.SET_CREATE_USER_ERROR,
      payload: error,
    });
  }

  addGuestAddress(address: UserDeliveryAddress) {
    return this.store.dispatch({
      type: AppStateActions.ADD_GUEST_ADDRESS,
      payload: address,
    });
  }

  clearNonTransferable() {
    return this.store.dispatch({
      type: AppStateActions.CLEAR_NON_TRANSFERABLE,
      payload: null,
    });
  }

  async getLocations(latitude: number, longitude: number) {
    return this.api
      .search(latitude, longitude)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SEARCH_LOCATIONS,
          payload: [...data],
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getLocationsByAddress(address: string) {
    return this.api
      .addressSearch(address)
      .then((data) => {
        const obj = {
          type: AppStateActions.SEARCH_LOCATIONS,
          payload: data,
        };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  clearLocations() {
    return this.store.dispatch({
      type: AppStateActions.SEARCH_LOCATIONS,
      payload: null,
    });
  }

  clearSelectedLocation() {
    return this.store.dispatch({
      type: AppStateActions.SET_LOCATION,
      payload: null,
    });
  }

  // Get a location
  // If we get a resoonse without an id then return null for the location
  async getLocation(
    id: string | number,
    update = true
  ): Promise<Location | any> {
    return this.api.location(id).then(async (l) => {
      if (update && l?.id) {
        return this.setLocation(l);
      }
      return l?.id ? l : null;
    });
  }

  async setLocation(location: Location | number | string) {
    if (typeof location === 'number' || typeof location === 'string') {
      location = await this.api.location(location);
    }
    await this.getMenu(location);
    // we get the payment provider when we select a new store
    // currently disabling WWT/Fiserv payment provider
    this.store.dispatch({
      type: AppStateActions.SET_PAYMENT_PROVIDER,
      payload:
        location &&
        location.nomnom &&
        location.nomnom.primary_pci_provider &&
        location.nomnom.primary_pci_provider !== PaymentProviders.WWT_FISERV
          ? location.nomnom.primary_pci_provider
          : PaymentProviders.PCI_PROXY,
    });
    this.setLocationAttributes(location);
    const obj = {
      type: AppStateActions.SET_LOCATION,
      payload: location,
    };
    this.store.dispatch(obj);
    return obj;
  }

  setMap(googleMap: google.maps.Map) {
    return this.store.dispatch({
      type: AppStateActions.SET_MAP,
      payload: googleMap,
    });
  }

  getMenu(location: Location, update = true): Promise<Menu | any> {
    const isDigitalMenu = this.state.getValue().appState.isDigitalMenu;
    return this.api
      .menu(location, isDigitalMenu)
      .then(async (data) => {
        if (update) {
          return this.setMenu(data);
        }
        return data;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async setMenu(menu: Menu) {
    const obj = {
      type: AppStateActions.SET_MENU,
      payload: menu,
    };
    this.store.dispatch(obj);
    return obj;
  }

  getGlobalMenu() {
    return this.getMenu(new Location({ id: environment.globalMenuId })).then(
      (data: any) => {
        const obj = {
          type: AppStateActions.SET_GLOBAL_MENU,
          payload: data.payload,
        };
        this.store.dispatch(obj);
        return obj;
      }
    );
  }

  clearProduct() {
    return this.store.dispatch({
      type: AppStateActions.SET_PRODUCT,
      payload: null,
    });
  }

  async refreshAuthentication() {
    if (this.state.getValue().appState.authentication) {
      // Attempt to load the contact details
      this.getContactDetails().catch(async (e) => {
        await this.clearAuthentication();
      });
    }
  }

  async modifyRecentOrder(order: OrderResponse) {
    return this.api
      .modifyOrder(order)
      .then((data: any) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async clearAuthentication() {
    if (this.state.getValue().appState.authentication) {
      await this.api
        .logout(this.state.getValue().appState.authentication)
        .catch((e) => {});

      // If we have ping auth details then we need to revoke the token
      if (
        this.state.getValue().appState.authentication.nomnom?.ping
          ?.refresh_token
      ) {
        await this.api
          .revokePingToken(this.state.getValue().appState.authentication)
          .catch((e) => {});
      }
    }
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_USER,
      payload: null,
    });
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_SESSION,
      payload: null,
    });
    const authPayload = this.store.dispatch({
      type: AppStateActions.AUTHENTICATE,
      payload: null,
    });

    // If we have a basket currently, we need to add all the products to it..
    if (
      this.state.getValue().appState.basket &&
      Array.isArray(this.state.getValue().appState.basket.products)
    ) {
      // Swap product id with basket id for update
      const existingProducts = this.state
        .getValue()
        .appState.basket.products.map((product: BasketProduct) => {
          let p = new BasketProduct({
            quantity: Number(product.quantity),
            productid: product.productId,
          });
          // Strip unnecessary properties out of the basket choice
          p.choices = product.choices.map((c) => {
            return new BasketChoice({
              choiceid: c.choiceid,
              quantity: c.quantity,
            });
          });
          return p;
        });
      // Update coupon value once basket has been created
      const existingCoupon = this.state.getValue().appState.basket.coupon;
      // Create a new basket at the location
      await this.createBasket(
        this.state.getValue().appState.basket.vendorid,
        this.state.getValue().appState.basket.timewanted,
        this.state.getValue().appState.basket.deliverymode,
        this.state.getValue().appState.basket.deliveryaddress
      );
      if (Array.isArray(existingProducts) && existingProducts.length > 0) {
        await this.addToBasket(existingProducts);
      }
      if (existingCoupon && existingCoupon.couponcode) {
        await this.applyCoupon(existingCoupon.couponcode);
      }
    }

    return authPayload;
  }

  clearBasket() {
    return this.store.dispatch({
      type: AppStateActions.SET_BASKET,
      payload: null,
    });
  }

  convertItemsNotTransferred(data: any) {
    let invalids: any[] = [];
    Object.keys(data).forEach((key) => {
      const num = parseFloat(key);
      if (!Number.isNaN(num) && num * 1 === num) {
        invalids.push(data[key]);
      }
    });
    return invalids;
  }

  async transferBasket(
    location: Location,
    mode: string = null,
    address: DeliveryAddress = null,
    maxAttempts = 3,
    attempts = 1
  ): Promise<any> {
    if (this.state.getValue().appState.selectedLocation.canCarryout()) {
      await this.makePickup().catch((e) => {
        throw e;
      });
    }
    return this.api
      .transfer(this.state.getValue().appState.basket, location)
      .then(async (newBasket) => {
        if (mode) {
          let handoffBasket: Basket;
          if (mode === Basket.MODE_PICKUP) {
            handoffBasket = await this._setDeliveryMode(
              Basket.MODE_PICKUP,
              newBasket
            ).catch((e) => {
              throw e;
            });
          } else if (mode === Basket.MODE_DINEIN) {
            handoffBasket = await this._setDeliveryMode(
              Basket.MODE_DINEIN,
              newBasket
            ).catch((e) => {
              throw e;
            });
          } else if (mode === Basket.MODE_DISPATCH) {
            handoffBasket = await this._setDispatchAddress(
              address,
              newBasket
            ).catch((e) => {
              throw e;
            });
          }
          if (Array.isArray(newBasket.itemsnottransferred)) {
            handoffBasket.itemsnottransferred = [
              ...newBasket.itemsnottransferred,
            ];
          }
          newBasket = handoffBasket;
        }
        if (!newBasket.itemsnottransferred) {
          newBasket.itemsnottransferred =
            this.convertItemsNotTransferred(newBasket);
        }
        return this.store.dispatch({
          type: AppStateActions.BASKET_TRANSFER,
          payload: newBasket,
        });
      })
      .catch(async (error) => {
        if (
          error.error.error.message.includes('Please make a selection') ||
          (!error.error.error.message.includes(
            'This location does not offer online ordering'
          ) &&
            !error.error.error.message.includes(
              'does not deliver to the selected delivery address'
            ) &&
            !error.error.error.message.includes(
              'No delivery services are available'
            ) &&
            !error.error.error.message
              .toLowerCase()
              .includes('does not offer dine in'))
        ) {
          await this.asyncForEach(
            this.state.getValue().appState.basket.products,
            async (product: BasketProduct) => {
              await this.api.deleteFromBasket(
                this.state.getValue().appState.basket,
                product
              );
            }
          );
          // retrying this call assumes that whatever was wrong can be fixed by deleting items in the basket
          // and will retry the same location again after deleting the products in the basket
          if (attempts < maxAttempts) {
            return this.transferBasket(
              location,
              mode,
              address,
              maxAttempts,
              attempts + 1
            );
          }
        } else {
          this.errors(error.error);
          throw error.error;
        }
      });
  }

  async asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  setProductRedirect(productRedirect: ProductRedirect) {
    return this.store.dispatch({
      type: AppStateActions.SET_PRODUCT_REDIRECT,
      payload: productRedirect,
    });
  }

  clearProductRedirect() {
    return this.store.dispatch({
      type: AppStateActions.SET_PRODUCT_REDIRECT,
      payload: null,
    });
  }

  markBasketAsDefaultTipped(basket: Basket | string) {
    if (typeof basket !== 'string') {
      basket = basket.id;
    }
    return this.store.dispatch({
      type: AppStateActions.MARK_BASKET_TIP,
      payload: basket,
    });
  }

  async getModifiers(
    location: Location,
    categoryProduct: CategoryProduct,
    set_product = true
  ) {
    return this.api
      .modifier(location, categoryProduct)
      .then((data) => {
        if (!set_product) {
          return data;
        }
        return this.store.dispatch({
          type: AppStateActions.SET_PRODUCT,
          payload: data,
        });
      })
      .catch((error: any) => {
        console.log(error);
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteCustomField(data: CustomField, basketId: any) {
    return this.api.deleteCustomField(data, basketId).then((r) => {
      return this.store.dispatch({
        type: AppStateActions.SET_BASKET,
        payload: r,
      });
    });
  }

  async setCustomField(data: CustomField, basketId: any) {
    return this.api.updateCustomField(data, basketId).then((r) => {
      return this.store.dispatch({
        type: AppStateActions.SET_BASKET,
        payload: r,
      });
    });
  }

  async createBasket(location: Location | number): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment,
    mode: string
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment,
    mode: string,
    address: DeliveryAddress
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted?: TimeWanted | moment.Moment,
    mode?: string,
    address?: DeliveryAddress
  ): Promise<any> {
    await this.setLocation(location);
    return this.api
      .createBasket(location, this.state.getValue().appState.authentication)
      .then(async (data) => {
        let dispatch = this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
        if (timeWanted) {
          // If there's a set time wanted...
          dispatch = await this.setTimeWanted(timeWanted);
        }

        if (!address && !mode) {
          // If no address provided and no mode return the basket
          return dispatch;
        } else if (!address && mode) {
          // If no address, but a mode, set the mode
          return this.setDeliveryMode(mode);
        } else if (address && mode) {
          // We have an address AND a mode
          if (mode === Basket.MODE_DISPATCH) {
            return this.makeDispatch(address);
          }
        }
        return dispatch;
      })
      .catch((error: any) => {
        console.log('error creating basket: ', error);
        let dispatch = this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: null,
        });
        this.errors(error.error || error);
        throw error.error || error;
      });
  }

  async createBasketFromOrder(order: OrderResponse) {
    return this.api
      .createBasketFromOrder(
        order,
        this.state.getValue().appState.authentication
      )
      .then(async (b) => {
        const obj = {
          type: AppStateActions.SET_BASKET,
          payload: b,
        };
        if (order.isCurbside()) {
          await this.makeCurbside();
        }
        this.store.dispatch(obj);
        await this.refreshBasket();
        return obj;
      })
      .catch((error: any) => {
        console.log(error);
        this.errors(error.error);
        throw error.error;
      });
  }

  async createBasketFromFave(order: FavoriteOrder) {
    return this.api
      .createBasketFromFave(
        order,
        this.state.getValue().appState.authentication
      )
      .then(async (b) => {
        const obj = {
          type: AppStateActions.SET_BASKET,
          payload: b,
        };
        await this.refreshBasket();
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        console.log(error);
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteFromBasket(product: BasketProduct) {
    return this.api
      .deleteFromBasket(this.state.getValue().appState.basket, product)
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          throw data.errors;
        }
        if (this.state.getValue().appState.basket.coupondiscount) {
          return this.validateBasket();
        }
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch(async (error: any) => {
        if (Array.isArray(error)) {
          throw error;
        }
        await this.refreshBasket();
        this.errors(error.error);
        throw error.error;
      });
  }

  async updateInBasket(products: BasketProduct[]) {
    // Swap product id with basket id for update
    const mutatedProducts = products.map((product) => {
      let p = new BasketProduct({
        quantity: Number(product.quantity),
        productid: product.id,
      });
      // Strip unnecessary properties out of the basket choice
      p.choices = product.choices.map((c) => {
        return new BasketChoice({ choiceid: c.choiceid, quantity: c.quantity });
      });
      return p;
    });

    return this.api
      .updateInBasket(this.state.getValue().appState.basket, mutatedProducts)
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          this.store.dispatch({
            type: AppStateActions.SET_BASKET,
            payload: data,
          });
          throw data.errors;
        }
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        console.log(error);
        if (Array.isArray(error)) {
          throw error;
        }
        this.errors(error.error);
        throw error.error;
      });
  }

  async refreshBasket() {
    if (this.state.getValue().appState.basket != null) {
      await this.getBasket();
      if (
        this.state.getValue().appState.basket != null &&
        this.state.getValue().appState.basket.vendorid
      ) {
        await this.getLocation(this.state.getValue().appState.basket.vendorid);
      }
    }
  }

  async getBasket() {
    return this.api
      .getBasket(this.state.getValue().appState.basket)
      .then((data) => {
        const obj = { type: AppStateActions.SET_BASKET, payload: data };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        // We couldn't find the basket or something else went horribly wrong
        // And since we couldn't find it, we need to clear it so no one
        // is stuck in a state where they can't order
        return this.clearBasket();
      });
  }

  async validateBasket(validate = true) {
    const auth = this.state.getValue().appState.authentication;
    const isFiservLocation =
      this.state.getValue().appState.paymentProvider ===
      PaymentProviders.WWT_FISERV;
    let userData = null;
    // if it's a fiserv location and the user is logged in
    // and we don't already have a Fiserv user in the store
    // ask the API to create/get a Fiserv user to use during checkout
    if (
      isFiservLocation &&
      auth &&
      (!auth.nomnom || !auth.nomnom.payment || !auth.nomnom.payment.customer)
    ) {
      // Need to get the contactdetails to pass through if they aren't already in the state
      let contactDetails = this.state.getValue().appState.contactDetails;
      if (!contactDetails) {
        await this.getContactDetails().then((detailsResponse) => {
          contactDetails = (<any>detailsResponse).payload;
        });
      }
      userData = {
        user_id: auth.nomnom.user_id,
        firstname: auth.firstname,
        lastname: auth.lastname,
        emailaddress: auth.emailaddress,
        contactdetails: contactDetails
          ? contactDetails.contactdetails
          : auth.contactdetails,
      };
    } else {
      // if we have user data in auth store slice but not in fiservUser slice
      if (
        !this.state.getValue().appState.fiservUser &&
        auth &&
        auth.nomnom &&
        auth.nomnom.payment &&
        auth.nomnom.payment.customer
      ) {
        this.store.dispatch({
          type: AppStateActions.SET_FISERV_USER,
          payload: auth.nomnom.payment.customer.data,
        });
      }
    }
    if (!validate) {
      return this.state.getValue().appState.basket;
    }
    return this.api
      .validate(this.state.getValue().appState.basket, userData)
      .then((data) => {
        if (
          data.nomnom &&
          data.nomnom.payment &&
          data.nomnom.payment.customer
        ) {
          this.store.dispatch({
            type: AppStateActions.SET_FISERV_USER,
            payload: data.nomnom.payment.customer.data,
          });
        }
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        if (error.error.error.message.toLowerCase().indexOf('coupon') > -1) {
          this.removeCoupon();
          return;
        }
        this.refreshBasket();
        if (!error.error || !error.error.error || !error.error.error.message) {
          error.error.error = {
            message: 'Unable to validate your basket - please try again later',
          };
        }
        this.errors(error.error);
        throw error.error;
      });
  }

  async addToBasket(products: BasketProduct[]) {
    if (this.state.getValue().appState.basket == null) {
      await this.createBasket(this.state.getValue().appState.selectedLocation);
    }

    return this.api
      .addToBasket(this.state.getValue().appState.basket, products)
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          throw data.errors;
        }
        this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
        return this.getBasket();
      })
      .catch(async (error: any) => {
        if (Array.isArray(error)) {
          throw error;
        }
        await this.refreshBasket();
        this.errors(error.error);
        throw error.error;
      });
  }

  async makePickup() {
    return this.setDeliveryMode(Basket.MODE_PICKUP)
      .then(async (data) => {
        return data;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async makeCurbside() {
    return this.setDeliveryMode(Basket.MODE_CURBSIDE)
      .then(async (data) => {
        return data;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async makeDinein() {
    return this.setDeliveryMode(Basket.MODE_DINEIN)
      .then(async (data) => {
        return data;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async makeDispatch(address: DeliveryAddress) {
    return this.setDispatchAddress(address).catch((error: any) => {
      this.errors(error.error);
      throw error.error;
    });
  }

  async _setDeliveryMode(mode: string, basket: Basket = null) {
    if (!basket) {
      basket = this.state.getValue().appState.basket;
    }
    return this.api.deliveryMode(basket, mode);
  }

  async setDeliveryMode(mode: string) {
    return this._setDeliveryMode(mode)
      .then(async (data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async _setDispatchAddress(address: DeliveryAddress, basket: Basket = null) {
    if (address && address.id) {
      address.id = 0;
    }
    if (!basket) {
      basket = this.state.getValue().appState.basket;
    }
    return this.api.dispatchAddress(basket, address);
  }

  async setDispatchAddress(address: DeliveryAddress) {
    return this._setDispatchAddress(address)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.errors(error.error);
        throw error.error;
      });
  }

  async setAsap() {
    return this.api
      .asap(this.state.getValue().appState.basket)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async setTimeWanted(timeWanted: TimeWanted | moment.Moment) {
    if (!(timeWanted instanceof TimeWanted)) {
      const m = moment(timeWanted);
      timeWanted = new TimeWanted({
        minute: parseInt(m.format('m'), 10),
        hour: parseInt(m.format('H'), 10),
        day: parseInt(m.format('D'), 10),
        month: parseInt(m.format('M'), 10),
        year: parseInt(m.format('Y'), 10),
      });
    }
    return this.api
      .timeWanted(this.state.getValue().appState.basket, timeWanted)
      .then((data) => {
        this.getBasket();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async authenticateWithGoogle(socialUser: SocialUser) {
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_USER,
      payload: null,
    });
    return this.api
      .authenticateWithGoogle(socialUser)
      .then((data) => {
        const authDispatch = this.store.dispatch({
          type: AppStateActions.AUTHENTICATE_GOOGLE,
          payload: data,
        });
        if (
          data.nomnom &&
          data.nomnom.payment &&
          data.nomnom.payment.customer
        ) {
          this.store.dispatch({
            type: AppStateActions.SET_FISERV_USER,
            payload: data.nomnom.payment.customer.data,
          });
        }
        this.getRecentOrders();
        return authDispatch;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async authenticate(
    login: Login | SocialUser | SSOUser,
    captcha?: string,
    provider?: string
  ) {
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_USER,
      payload: null,
    });
    if (login instanceof SocialUser) {
      login = new SSOUser({
        provider: provider,
        providertoken: login.authToken,
      });
    }

    return this.api
      .authenticate(login, captcha)
      .then(async (data) => {
        if (
          data.nomnom &&
          data.nomnom.payment &&
          data.nomnom.payment.customer
        ) {
          this.store.dispatch({
            type: AppStateActions.SET_FISERV_USER,
            payload: data.nomnom.payment.customer.data,
          });
        }
        const authProvider = provider ? provider.toLowerCase() : null;
        const authDispatch = this.store.dispatch({
          type: AppStateActions.AUTHENTICATE,
          payload: { ...data, provider: authProvider },
        });
        // Load the recent orders
        this.getRecentOrders();

        // If we have a basket currently, we need to add all the products to it..
        if (
          this.state.getValue().appState.basket &&
          Array.isArray(this.state.getValue().appState.basket.products)
        ) {
          // Swap product id with basket id for update
          const existingProducts = this.state
            .getValue()
            .appState.basket.products.map((product: BasketProduct) => {
              let p = new BasketProduct({
                quantity: Number(product.quantity),
                productid: product.productId,
              });
              // Strip unnecessary properties out of the basket choice
              p.choices = product.choices.map((c) => {
                return new BasketChoice({
                  choiceid: c.choiceid,
                  quantity: c.quantity,
                });
              });
              return p;
            });
          // Update coupon value once basket has been created
          const existingCoupon = this.state.getValue().appState.basket.coupon;

          // Create a new basket at the location
          await this.createBasket(
            this.state.getValue().appState.basket.vendorid,
            this.state.getValue().appState.basket.timewanted,
            this.state.getValue().appState.basket.deliverymode,
            this.state.getValue().appState.basket.deliveryaddress
          );
          if (Array.isArray(existingProducts) && existingProducts.length > 0) {
            await this.addToBasket(existingProducts);
          }
          if (existingCoupon && existingCoupon.couponcode) {
            await this.applyCoupon(existingCoupon.couponcode);
          }
        }

        return authDispatch;
      })
      .catch((error: any) => {
        this.store.dispatch({
          type: AppStateActions.SET_FISERV_USER,
          payload: null,
        });
        this.errors(error.error);
        throw error.error;
      });
  }

  percentage(percent: number, n: number) {
    return (n * (percent / 100)).toUSD();
  }

  async checkout(payment: any) {
    if (this.state.getValue().appState.authentication != null) {
      payment.authtoken =
        this.state.getValue().appState.authentication.authtoken;
      payment.usertype = 'user';
    }
    const isPayInStore =
      payment.billingaccounts &&
      payment.billingaccounts.some(
        (p: BillingAccount) => p.billingmethod === PaymentTypes.CASH
      );

    const isExternalPayment =
      payment.billingmethod === 'digitalwallet' ? true : false;

    // only use new endpoint if using a WWT/Fiserv location or for payinstore/cash payments
    if (
      this.state.getValue().appState.paymentProvider ===
        PaymentProviders.WWT_FISERV &&
      this.state.getValue().appState.locationSupportsPrepaid &&
      !isPayInStore &&
      !isExternalPayment
    ) {
      return this.api
        .wwtSubmit(this.state.getValue().appState.basket, payment)
        .then((data: any) => {
          this.store.dispatch({
            type: AppStateActions.SET_LAST_SESSION_ORDER,
            payload: new LastSessionOrder({
              basket: cloneDeep(this.state.getValue().appState.basket),
              paymentData: cloneDeep(payment),
              orderResponse: cloneDeep(data),
            }),
          });
          this.viewedConfirmationPage(false);
          return data;
        })
        .catch(async (error: any) => {
          // await this.validateBasket();
          this.errors(error.error);
          throw error.error;
        });
    }

    // Used for olo external providers (i.e. Apple Pay)
    if (isExternalPayment) {
      return this.api
        .externalSinglePayment(this.state.getValue().appState.basket, payment)
        .then((data: any) => {
          this.store.dispatch({
            type: AppStateActions.SET_LAST_SESSION_ORDER,
            payload: new LastSessionOrder({
              basket: cloneDeep(this.state.getValue().appState.basket),
              paymentData: cloneDeep(payment),
              orderResponse: cloneDeep(data),
            }),
          });
          this.viewedConfirmationPage(false);
          return data;
        })
        .catch(async (error: any) => {
          // await this.validateBasket();
          this.errors(error.error);
          throw error.error;
        });
    }

    return this.api
      .multiplePayments(this.state.getValue().appState.basket, payment)
      .then((data: any) => {
        this.store.dispatch({
          type: AppStateActions.SET_LAST_SESSION_ORDER,
          payload: new LastSessionOrder({
            basket: cloneDeep(this.state.getValue().appState.basket),
            paymentData: cloneDeep(payment),
            orderResponse: cloneDeep(data),
          }),
        });
        this.viewedConfirmationPage(false);
        return data;
      })
      .catch(async (error: any) => {
        // await this.validateBasket();
        this.errors(error.error);
        throw error.error;
      });
  }

  async setSearchLocation(address: GeocodeResponse) {
    return this.api
      .search(address.latitude, address.longitude)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SEARCH_LOCATIONS,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getRecentOrders() {
    return this.api
      .recent(this.state.getValue().appState.authentication)
      .then((data) => {
        const obj = { type: AppStateActions.RECENT, payload: data };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getUserAddresses() {
    return this.api
      .deliveryAddresses(this.state.getValue().appState.authentication)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.USER_ADDRESSES,
          payload: uniqBy(
            data.sort((a: DeliveryAddress, b: DeliveryAddress) => b.id - a.id),
            (val: DeliveryAddress) =>
              [val.streetaddress, val.state, val.city, val.building].join()
          ),
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getUserBillingAccounts() {
    return this.api
      .billingAccounts(this.state.getValue().appState.authentication)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.USER_BILLING_ACCOUNTS,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getUserFavoriteLocations() {
    return this.api
      .favoriteLocations(this.state.getValue().appState.authentication)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.USER_FAVORITE_LOCATIONS,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getFavoriteOrders() {
    return this.api
      .favoriteOrders(this.state.getValue().appState.authentication)
      .then((data) => {
        const obj = { type: AppStateActions.FAVORITE_ORDERS, payload: data };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getContactOptions() {
    return this.api
      .contactOptions(this.state.getValue().appState.authentication)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.USER_CONTACT_OPTIONS,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async getContactDetails() {
    return this.api
      .contactDetails(this.state.getValue().appState.authentication)
      .then((data) => {
        const obj = {
          type: AppStateActions.USER_CONTACT_DETAILS,
          payload: data,
        };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async updateContactDetails(contactDetails: ContactDetails) {
    return this.api
      .updateContactDetails(
        this.state.getValue().appState.authentication,
        contactDetails
      )
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.USER_CONTACT_DETAILS,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  setRedirect(redirect: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_REDIRECT,
      payload: redirect,
    });
  }

  setRememberMe(rememberMe: RememberMe) {
    return this.store.dispatch({
      type: AppStateActions.SET_REMEMBER_ME,
      payload: rememberMe,
    });
  }

  async createUserFromOrder(
    order: OrderResponse | string,
    password: string,
    optin = false
  ) {
    return this.api
      .createUserFromOrder(order, password, optin)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.AUTHENTICATE,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async addFavoriteLocation(location: Location) {
    return this.api
      .addFavoriteLocation(
        this.state.getValue().appState.authentication,
        location
      )
      .then((data) => {
        return this.getUserFavoriteLocations();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async addFavoriteBasket(basket: Basket, description: string) {
    return this.api
      .addFavoriteOrder(
        this.state.getValue().appState.authentication,
        basket,
        description
      )
      .then((b) => {
        return this.getFavoriteOrders();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async addFavoriteOrder(order: OrderResponse, description: string) {
    return this.api
      .createBasketFromOrder(
        order,
        this.state.getValue().appState.authentication
      )
      .then(async (basket) => {
        return this.addFavoriteBasket(basket, description);
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteUserDeliveryAddress(userDeliveryAddress: UserDeliveryAddress) {
    return this.api
      .deleteDeliveryAddress(
        this.state.getValue().appState.authentication,
        userDeliveryAddress
      )
      .then((data) => {
        return this.getUserAddresses();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteFavoriteLocation(location: Location) {
    return this.api
      .deleteFavoriteLocation(
        this.state.getValue().appState.authentication,
        location
      )
      .then((data) => {
        return this.getUserFavoriteLocations();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteFavoriteOrder(favoriteOrder: FavoriteOrder | number) {
    return this.api
      .deleteFavoriteOrder(
        this.state.getValue().appState.authentication,
        favoriteOrder
      )
      .then((data) => {
        return this.getFavoriteOrders();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteUserBillingAccount(
    userBillingAccount: UserBillingAccount | number | any
  ) {
    return this.api
      .deleteBillingAccount(
        this.state.getValue().appState.authentication,
        userBillingAccount
      )
      .then((data) => {
        return this.getUserBillingAccounts();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }
  async deleteFiservBillingAccount(oloCustomerId: string, fdAccountId: string) {
    return this.api
      .deleteFiservAccount(oloCustomerId, fdAccountId)
      .then((data: any) => {
        return this.getUserBillingAccounts();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async cancelRecentOrder(order: OrderResponse, invoiceId?: string) {
    try {
      const data = await this.api.cancelOrder(order, invoiceId);
      if (data) {
        return this.getRecentOrders();
      }
    } catch (error) {
      this.errors(error.error);
      throw error.error;
    }
  }

  async createUser(createUser: SignUp) {
    return this.api
      .createUser(createUser)
      .then((data) => {
        if (
          data.nomnom &&
          data.nomnom.payment &&
          data.nomnom.payment.customer
        ) {
          this.store.dispatch({
            type: AppStateActions.SET_FISERV_USER,
            payload: data.nomnom.payment.customer.data,
          });
        }
        return this.store.dispatch({
          type: AppStateActions.AUTHENTICATE,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async updateUser(updateUser: UpdateUser) {
    return this.api
      .updateUser(this.state.getValue().appState.authentication, updateUser)
      .then((data) => {
        const authProvider = this.state.getValue().appState.authentication
          ?.provider
          ? this.state
              .getValue()
              .appState.authentication?.provider.toLowerCase()
          : null;

        return this.store.dispatch({
          type: AppStateActions.AUTHENTICATE,
          payload: {
            ...data,
            provider: authProvider,
          },
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async updatePassword(updatePassword: UpdatePassword) {
    return this.api
      .updatePassword(
        this.state.getValue().appState.authentication,
        updatePassword
      )
      .then((data) => {})
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async updateContactOptions(updateContactOptions: UpdateContactOptions) {
    return this.api
      .updateContactOptions(
        this.state.getValue().appState.authentication,
        updateContactOptions
      )
      .then((data) => {})
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async deleteAccount() {
    return this.api
      .deleteAccount(this.state.getValue().appState.authentication)
      .then((data) => {})
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async forgotPassword(emailAddress: string) {
    return this.api
      .forgotPassword(emailAddress)
      .then((data) => {})
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  // Might want to consider moving this logic to the service...
  async applyTip(index: number, tip?: number, isDefault?: boolean) {
    // If the default flag is true, we're setting a default tip value
    if (isDefault) {
      this.store.dispatch({
        type: AppStateActions.SET_DEFAULT_TIP,
        payload: true,
      });
    } else if (isDefault === false) {
      // If it is EXPLICITLY not true and not null, it must be false and the user manually set the tip
      this.store.dispatch({
        type: AppStateActions.SET_USER_TIP,
        payload: true,
      });
    }
    if (index !== null) {
      // If the user selected an index, we're going to save that, and re-apply it anytime the basket and tip changes
      this.store.dispatch({
        type: AppStateActions.SET_TIP_INDEX,
        payload: index,
      });
    } else {
      this.store.dispatch({
        type: AppStateActions.SET_TIP_INDEX,
        payload: null,
      });
    }
    if (tip != null || (tip == null && index == null)) {
      // Apply the actual tip
      return this.api
        .applyTip(this.state.getValue().appState.basket, tip == null ? 0 : tip)
        .then((data) => {
          return this.store.dispatch({
            type: AppStateActions.SET_BASKET,
            payload: data,
          });
        })
        .catch((error: any) => {
          this.errors(error.error);
          throw error.error;
        });
    }
  }

  async applyCoupon(coupon: string) {
    return this.api
      .applyCoupon(this.state.getValue().appState.basket, coupon)
      .then((data) => {
        return this.validateBasket();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async removeCoupon() {
    return this.api
      .removeCoupon(this.state.getValue().appState.basket)
      .then((data) => {
        return this.validateBasket();
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  errors(errors: any) {
    return this.store.dispatch({
      type: AppStateActions.SET_ERRORS,
      payload: errors,
    });
  }

  clearErrors() {
    return this.errors(null);
  }

  appendError(str: string) {
    return this.appendErrors([str]);
  }

  appendErrors(strArr: string[]) {
    let errs = this.state.getValue().appState.errors;
    if (!errs) {
      return this.errors(strArr);
    } else if (Array.isArray(errs)) {
      return this.errors([errs.slice(), ...strArr]);
    } else {
      return this.errors([errs, ...strArr]);
    }
  }

  openModalWithErrors(title?: string, subTitle?: string, buttonLabel?: string) {
    let errs = this.state.getValue().appState.errors;
    if (errs) {
      return this.openAlertModalWith(
        title,
        Array.isArray(errs) ? errs.slice() : errs,
        buttonLabel,
        subTitle
      );
    } else {
      return Promise.resolve();
    }
  }

  openAlertModalWith(
    title: string,
    description: string | string[],
    buttonLabel = 'OK',
    subTitle?: string,
    closeButton?: string,
    hasCloseButton?: boolean,
    hasFormField?: boolean,
    callback?: (result: string) => void,
    image?: string,
    topRightCloseButton?: boolean,
    name?: string,
    buttonAriaLabel?: string
  ) {
    const data: AlertModel = new AlertModel({
      title: title ? title : 'Alert',
      subTitle,
      closeButton,
      hasCloseButton,
      hasFormField,
      description: Array.isArray(description) ? description : [description],
      button: buttonLabel,
      buttonAriaLabel,
      callback,
      image,
      topRightCloseButton,
      name,
    });

    // Chrome-only bug, have to create the modal with a timeout
    // reference: https://github.com/angular/angular/issues/17572
    return setTimeout(() => {
      return this.openAlertModal(data);
    });
  }

  openAlertModal(data: AlertModel) {
    return this.store.dispatch({
      type: AppStateActions.OPEN_ALERT_MODAL,
      payload: data,
    });
  }

  closeAlertModal() {
    return this.store.dispatch({
      type: AppStateActions.CLOSE_ALERT_MODAL,
    });
  }

  confirmAlert() {
    return this.store.dispatch({
      type: AppStateActions.CONFIRM_ALERT,
    });
  }

  discardAlert() {
    return this.store.dispatch({
      type: AppStateActions.DISCARD_ALERT,
    });
  }

  setFavoriteName(favoriteName: string | null) {
    return this.store.dispatch({
      type: AppStateActions.SET_FAVORITE_NAME,
      payload: favoriteName,
    });
  }

  async userLoggedOut() {
    this.store.dispatch({
      type: AppStateActions.RECENT,
      payload: [],
    });
    this.store.dispatch({
      type: AppStateActions.USER_FAVORITE_LOCATIONS,
      payload: [],
    });
    await this.clearAuthentication();
    return this.store.dispatch({
      type: AppStateActions.USER_LOGGED_OUT,
    });
  }

  // Once password has changed the authtoken is no longer valid.
  async userChangedPassword() {
    this.store.dispatch({
      type: AppStateActions.AUTHENTICATE,
      payload: null,
    });
    return this.userLoggedOut();
  }

  async setClubForm(data: ClubModel) {
    data.birthDate = moment(
      data.birthDateDay + ' ' + data.birthDateMonth + ' ' + data.birthDateYear
    ).format('MM-DD-YYYY');
    return this.api.clubForm(data).then((res) => {
      return this.store.dispatch({
        type: AppStateActions.SET_CLUB_FORM,
        payload: res,
      });
    });
  }

  viewedConfirmationPage(viewed = true) {
    return this.store.dispatch({
      type: AppStateActions.CONFIRMATION_PAGE_VIEWED,
      payload: viewed,
    });
  }

  setCmsOfferToUse(offer: CMSPromoOffer) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER,
      payload: offer,
    });
  }

  setCmsOfferRedeemCode(redeemCode: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER_REDEEM_CODE,
      payload: redeemCode,
    });
  }

  setCmsOfferTimestamp(timestamp: number) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER_TIMESTAMP,
      payload: timestamp,
    });
  }

  setToolTipSeen(seen: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_TOOLTIP_SEEN,
      payload: seen,
    });
  }
  setGeolocation(approved: string) {
    return this.store.dispatch({
      type: AppStateActions.GEOLOCATION_ALLOWED,
      payload: approved,
    });
  }
  setGuestCheckoutEmail(email: string) {
    let guests = this.state.getValue().appState.guestCheckoutEmail || [];
    guests.push(email);
    return this.store.dispatch({
      type: AppStateActions.GUEST_CHECKOUT_EMAIL,
      payload: guests,
    });
  }

  setCurrentProductSelections(parentFormValue: any) {
    return this.store.dispatch({
      type: AppStateActions.SET_CURRENT_PRODUCT_SELECTIONS,
      payload: parentFormValue,
    });
  }

  addUpsellProduct(upsellProduct: any) {
    let upsellProducts = this.state.getValue().appState.upsellProducts || [];
    upsellProducts ? upsellProducts.push(upsellProduct) : (upsellProducts = []);
    return this.store.dispatch({
      type: AppStateActions.ADD_UPSELL_PRODUCT,
      payload: upsellProducts,
    });
  }

  clearUpsellProducts() {
    return this.store.dispatch({
      type: AppStateActions.CLEAR_UPSELL_PRODUCTS,
      payload: [],
    });
  }

  getUpsellProduct(id: number) {
    let upsellProducts = this.state.getValue().appState.upsellProducts;
    let upsellProduct =
      upsellProducts && upsellProducts.length
        ? upsellProducts.find(
            (upsell: any) => upsell && upsell.id && upsell.id === id
          )
        : null;

    if (upsellProduct) {
      if (upsellProduct.type === UpsellTypes.BUTTON) {
        upsellProduct.gaType = UpsellGaTypes.MAKE_IT_A_COMBO;
      } else if (
        upsellProduct.type === UpsellTypes.CAROUSEL &&
        !upsellProduct.name.includes('fries')
      ) {
        upsellProduct.gaType = UpsellGaTypes.UPSELL_WINGS;
      } else {
        upsellProduct.gaType = UpsellGaTypes.UPSELL_FRIES;
      }
    }

    const obj = {
      type: AppStateActions.GET_UPSELL_PRODUCT,
      payload: upsellProduct,
    };
    this.store.dispatch(obj);
    return obj;
  }

  removeUpsellProduct(id: number | string) {
    const upsellProducts = this.state.getValue().appState.upsellProducts;
    const index = upsellProducts
      ? upsellProducts.findIndex((product: any) => product.id === id)
      : -1;
    const updatedUpsellProducts =
      index > -1
        ? upsellProducts.filter((product: any, i: number) => i !== index)
        : upsellProducts;

    return this.store.dispatch({
      type: AppStateActions.REMOVE_UPSELL_PRODUCT,
      payload: updatedUpsellProducts,
    });
  }

  async optOutDoorDash(emailAddress: string) {
    return this.api
      .optOutDoorDash(emailAddress)
      .then((data) => {})
      .catch((error: any) => {});
  }

  setIsUserFirstThreeMonths() {
    const isWithinFirstThreeMonths =
      this.userIdentityService.isWithinFirstThreeMonths();
    return this.store.dispatch({
      type: AppStateActions.SET_USER_FIRST_THREE_MONTHS,
      payload: isWithinFirstThreeMonths,
    });
  }

  setCmsFeatureFlags() {
    return this.api.getCmsFeatureFlags().then((flags) => {
      if (flags) {
        const pilotProgram: IPilotProgram = {
          programEnabled: flags.some(
            (flag) =>
              flag.name === 'PilotProgramEnabled' &&
              flag.enabled &&
              flag.isActive
          )
            ? true
            : false,
          forceLogoutEnabled: flags.some(
            (flag) =>
              flag.name === 'PilotForceLogoutEnabled' &&
              flag.enabled &&
              flag.isActive
          )
            ? true
            : false,
          redirectEnabled: flags.some(
            (flag) =>
              flag.name === 'PilotRedirectEnabled' &&
              flag.enabled &&
              flag.isActive
          )
            ? true
            : false,
        };

        this.store.dispatch({
          type: AppStateActions.SET_PILOT_PROGRAM_FEATURE_FLAGS,
          payload: pilotProgram,
        });

        this.store.dispatch({
          type: AppStateActions.SET_SECRET_MENU_FEATURE_FLAG,
          payload: flags.some(
            (flag) =>
              flag.name === 'SecretMenu' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });

        this.store.dispatch({
          type: AppStateActions.SET_USER_REGISTRATION_FEATURE_FLAG,
          payload: flags.some(
            (flag) =>
              flag.name === 'UserRegistration' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });

        this.store.dispatch({
          type: AppStateActions.SET_ROUND_UP_DONATION_FEATURE_FLAG,
          payload: flags.some(
            (flag) =>
              flag.name === 'RoundupDonations' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });

        this.store.dispatch({
          type: AppStateActions.SET_APPLE_PAY_FEATURE_FLAG,
          payload: flags.some(
            (flag) => flag.name === 'ApplePay' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });

        this.store.dispatch({
          type: AppStateActions.SET_COKE_FREESTYLE_FEATURE_FLAG,
          payload: flags.some(
            (flag) =>
              flag.name === 'CokeFreestyle' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });

        this.store.dispatch({
          type: AppStateActions.SET_APP_DOWNLOAD_BANNER_FEATURE_FLAG,
          payload: flags.some(
            (flag) =>
              flag.name === 'AppDownloadBanner' && flag.enabled && flag.isActive
          )
            ? true
            : false,
        });
      }
    });
  }

  setIsDigitalMenu(isDigitalMenu: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_IS_DIGITAL_MENU,
      payload: isDigitalMenu,
    });
  }

  setStartOrderSource(source: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_START_ORDER_SOURCE,
      payload: source,
    });
  }

  setIsReorder(isReorder: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_IS_REORDER,
      payload: isReorder,
    });
  }

  setDigitalMenuScrollTo(scrollToCategory: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_DIGITAL_MENU_SCROLL_TO,
      payload: scrollToCategory,
    });
  }

  setDigitalMenuLoaded(digitalMenuLoaded: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_DIGITAL_MENU_LOADED,
      payload: digitalMenuLoaded,
    });
  }

  setRoundUpSelected(roundUpSelected: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_ROUND_UP_SELECTED,
      payload: roundUpSelected,
    });
  }

  setRoundUpAmount() {
    const basket = this.state.getValue().appState.basket;

    let roundUpAmount;
    if (basket && basket.subtotal === 0) {
      roundUpAmount = 0;
    } else {
      const totalMinusDonations = basket.total - basket.totaldonations;
      const change = totalMinusDonations - Math.floor(totalMinusDonations);
      roundUpAmount = change === 0 ? 1.0 : 1 - change;
    }

    return this.store.dispatch({
      type: AppStateActions.SET_ROUND_UP_AMOUNT,
      payload: Number(roundUpAmount.toFixed(2)),
    });
  }

  applyDonation(id: number, amount: number, basketId: string) {
    return this.api
      .applyDonation(id, amount, basketId)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  setLocationAttributes(location: Location) {
    if (location) {
      this.store.dispatch({
        type: AppStateActions.SET_COKE_FREESTYLE,
        payload: location.hasCokeFreestyle(),
      });
    }
  }

  setShowAppBanner(showAppBanner: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_SHOW_APP_BANNER,
      payload: showAppBanner,
    });
  }

  setAppBannerClosedByUser(appBannerClosedByUser: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_APP_BANNER_CLOSED_BY_USER,
      payload: appBannerClosedByUser,
    });
  }

  setUserLocale(userLocale: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_USER_LOCALE,
      payload: userLocale,
    });
  }

  setS3SeoMetadata() {
    return this.api.getS3SeoMetadata().then((seoMetadata) => {
      if (seoMetadata) {
        return this.store.dispatch({
          type: AppStateActions.SET_S3_SEO_METADATA,
          payload: seoMetadata,
        });
      }
    });
  }

  setPilotProgramUser(pilotProgramUser: IPilotProgramUser) {
    return this.store.dispatch({
      type: AppStateActions.SET_PILOT_PROGRAM_USER,
      payload: pilotProgramUser,
    });
  }

  setDetailedBasketAddress(address: UserDeliveryAddress) {
    return this.store.dispatch({
      type: AppStateActions.SET_DETAILED_BASKET_DELIVERY_ADDRESS,
      payload: address,
    });
  }
}
